import dynamic from 'next/dynamic'
import Head from 'next/head'
import Link from 'next/link'

import { recent } from '../queries/articles'
// import { featuredProducts } from '../queries/products'
import ApiClient from '../utils/client'

const Layout = dynamic(() => import('../components/layout'))
const Grid = dynamic(() => import('../components/organisms/grid'))
const Articles = dynamic(() => import('../components/organisms/blog-content'))
// const Products = dynamic(
//   () => import('../components/organisms/product-content')
// )
const VideoHero = dynamic(() => import('../components/molecules/video-hero'))
const PrimelaseLogo = dynamic(() => import('../assets/primelase-logo.svg'))
const Hair = dynamic(() => import('../assets/hair-removal.svg'))
const Makeup = dynamic(() => import('../assets/makeup.svg'))
const Face = dynamic(() => import('../assets/face-treatment.svg'))
const Klarna = dynamic(() => import('../components/klarna'), {
  ssr: false,
})
const Location = dynamic(() => import('../components/molecules/location'))

interface GridItem {
  title: string
  link: string
  image?: string
}

const items: GridItem[] = [
  {
    title: 'Injectables',
    link: '/dermal-fillers/',
    // image: '/services/aesthetics/filler-in-progress.jpg',
    image: '/services/aesthetics/dermal-fillers-at-charmelle-london.png',
  },
  {
    title: 'Anti Wrinkle Injections',
    link: '/anti-wrinkle-injections/',
    image: '/services/fillers/anti-wrinkle-injections.jpg',
  },
  {
    title: 'Skin Boosters',
    link: '/skin-boosters/',
    // image: '/services/aesthetics/skin-boosters.jpeg',
    image:
      '/services/aesthetics/skin-boosters.jpeg',
  },
  {
    title: 'Laser Hair Removal',
    link: '/laser-hair-removal/',
    image: '/services/laser/primelase-machine.jpeg',
  },
  {
    title: 'Facials',
    link: '/treatments/face/',
    // image: '/services/skinceuticals/professional-treatments.jpg',
    image: '/services/facials/deep-cleanse.jpg',
  },
  {
    title: 'HydroFacial',
    link: '/hydrafacial/',
    image:
      'https://solunalondon.com/_next/image/?url=https%3A%2F%2Fs3.eu-west-2.amazonaws.com%2Fassets.charmelle.london%2F1647517947456-Image%20from%20iOS.jpg&w=3840&q=75',
  },
  {
    title: 'Body',
    link: '/treatments/body/',
    // image: '/services/body/bum-lift.jpg',
    image:
      '/services/body/radio-frequency-skin-tightening.jpg',
  },
  {
    title: 'Weight Loss',
    link: '/treatments/weight-loss/',
    image: '/services/body/cryo-treatment.jpg',
  },
  {
    title: 'Luxury Facials',
    link: '/treatments/face/',
    image: '/services/facials/deep-cleanse.jpg',
  },
  {
    title: 'Semi Permanent Makeup',
    link: '/permanent-makeup/',
    // image: '/services/spm/colour-boost.png',
    image:
      '/services/spm/spm.png',
  },
  {
    title: 'HiFu',
    link: '/hifu/',
    image: '/services/facials/hifu.jpg',
  },
  {
    title: 'Hollywood Facial',
    link: '/hollywood-facial/',
    image: '/services/facials/hollywood-facial.jpg',
  },
]

export default function Home(props) {
  return (
    <Layout>
      <Head>
        <title>
          Soluna London | Laser Hair Removal, Dermal Fillers, Permanent
          Makeup
        </title>
        <meta
          name="description"
          content="Industry leading skincare, facials, injectables, laser hair removal, bespoke semi-permanent makeup and much more in London. Call 0333 016 3500"
        />
        <link rel="canonical" href={`https://solunalondon.com/`} />
        <link
          rel="alternate"
          hrefLang="en-GB"
          href={`https://solunalondon.com/`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://solunalondon.com/`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://solunalondon.com/`}
        />
      </Head>
      <h2 className="sr-only">
        Browse our extensive services at Soluna London which includes the
        fastest laser hair removal suitable for all skin types, dermal fillers
        and advanced body treatments from our clinic based in Bromley
      </h2>

      <VideoHero
        heading="Laser Hair Removal, Aesthetics & Permanent Makeup Specialists"
        text="Browse our extensive services at Soluna London which includes the
        fastest laser hair removal suitable for all skin types, dermal
        fillers and advanced body treatments from our clinic based in
        Bromley"
        ctas={[
          { text: 'Book now', link: '/booking/' },
          { text: 'View treatments', link: '/treatments/' },
        ]}
        source={[
          { src: '/videos/introduction.webm', type: 'video/webm' },
          { src: '/videos/introduction.mp4', type: 'video/mp4' },
        ]}
        attrs={{
          preload: 'false',
          autoPlay: false,
          loop: true,
          muted: true,
          playsInline: true,
        }}
      />

      <div className="mt-16 md:mt-36 mx-4">
        <Grid columns={4} items={items} />
      </div>

      <section className="my-16 md:my-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <h2 className="font-serif">
          <span className="mt-1 block text-4xl font-medium sm:text-5xl xl:text-6xl">
            <span className="block text-gray-900">
              Exceptional <span className="text-brand-600">Experiences</span>
            </span>
            <span className="block text-gray-900">
              Uncompromising <span className="text-brand-600">Results</span>
            </span>
          </span>
        </h2>
        <p className="mt-6 text-lg text-gray-600 max-w-xl">
          Excellence, expertise & experience are at the heart of everything we
          do. Customer satisfaction is our number one goal - we believe that
          everyone is different and therefore work with you to create the right
          treatment and homecare, tailored to your needs and goals.
        </p>
      </section>

      <div className="relative bg-white pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 md:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brand-600">
                    <Hair
                      // @ts-ignore
                      fill="white"
                      className="h-6 w-6 text-white"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-medium text-gray-900 mb-3 font-serif">
                    State of the art laser hair removal technology
                  </h2>
                  {/* @ts-ignore */}
                  <PrimelaseLogo width="130" />
                  <p className="mt-4 text-lg text-gray-600">
                    Suitable for all skin types and even tanned skin - our laser
                    hair removal boasts fast treatment times with maximum
                    results.
                  </p>
                  <div className="mt-6 flex md:max-w-xs">
                    <Link
                      href="/laser-hair-removal/"
                      passHref
                      className="btn-primary"
                    >
                      Learn more
                      <span className="sr-only">
                        about Laser Hair Removal at Soluna London
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-600">
                      Quick sessions, great results and a super friendly team
                    </p>
                  </div>
                  <footer className="pl-4">
                    <div className="flex items-center space-x-3">
                      <div className="text-base font-serif font-medium text-gray-700">
                        Katie Bell
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  loading="lazy"
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="/services/laser/primelase-machine.jpeg"
                  alt="State of the art laser hair removal technology"
                  width="850"
                  height="565"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 md:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brand-600">
                    <Face
                      // @ts-ignore
                      fill="white"
                      className="h-6 w-6 text-white"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-medium text-gray-900 font-serif">
                    Expert skincare solutions
                  </h2>
                  <p className="mt-4 text-lg text-gray-600">
                    With years of combined experience and in-depth skin and
                    product knowledge we can relieve any conditions you have.
                  </p>
                  <div className="mt-6 flex md:max-w-xs">
                    <Link
                      href="/treatments/face/"
                      passHref
                      className="btn-primary"
                    >
                      Learn more
                      <span className="sr-only">
                        about expert skin solutions and facials at Soluna
                        London
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  loading="lazy"
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="/services/facials/detox.jpg"
                  alt="Customer profile user interface"
                  width="785"
                  height="525"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-28">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 md:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-brand-600">
                    <Makeup
                      // @ts-ignore
                      fill="white"
                      className="h-6 w-6 text-white"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-medium text-gray-900 mb-3 font-serif">
                    Expertly crafted permanent makeup
                  </h2>
                  <p className="mt-4 text-lg text-gray-600">
                    Microbladed, 3D or ombre brows, eyeliner and lip blush - our
                    experienced team excel in their craft
                  </p>
                  <div className="mt-6 flex md:max-w-xs">
                    <Link
                      href="/permanent-makeup/"
                      passHref
                      className="btn-primary"
                    >
                      Learn more
                      <span className="sr-only">
                        about permanent makeup at Soluna London
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-600">
                      Attentive to what I wanted and truly delivered beyond my
                      expectations!
                    </p>
                  </div>
                  <footer className="pl-4">
                    <div className="flex items-center space-x-3">
                      <div className="text-base font-serif font-medium text-gray-700">
                        Harriet
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  loading="lazy"
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="/services/spm/spm.png"
                  alt="Expertly crafted semi permanent makeup"
                  width="785"
                  height="565"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-10">
        <Klarna data-key="top-strip-promotion-auto-size" data-locale="en-GB" />
      </div>

      {/* {props.products && <Products products={props.products} />} */}

      {props.articles && <Articles articles={props.articles} />}

      <section aria-labelledby="locationHeading">
        <div className="relative bg-gray-50 pt-12">
          <h2
            id="locationHeading"
            className="text-2xl lg:text-4xl text-gray-900 mb-8 font-serif text-center font-medium"
          >
            Our Location
          </h2>
          <p className="mt-6 text-lg text-gray-600 max-w-3xl text-center mx-auto">
            Based close to mainline train stations and served by frequent
            running bus routes our clinic offers privacy with underground
            parking available.
          </p>
          <p className="sr-only">
            <a
              href="https://goo.gl/maps/iuZz1pknRrU3gevR7"
              target="_blank"
              rel="noopener noreferrer"
              className="flex transition-all duration-100 hover:text-brand-600"
            >
              Soluna London is based at 37a London Road, Bromley, London, BR1
              1DG
            </a>
          </p>
          <div className="mt-10 overflow-hidden rounded-md max-w-7xl mx-auto">
            <Location />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export async function getStaticProps({ params, preview = false }) {
  const client = ApiClient()

  const articles = await client.query({
    query: recent,
  })

  // const products = await client.query({
  //   query: featuredProducts,
  // })

  const props = {
    articles: articles.data.content,
    // products: products.data.content,
  }

  return {
    props,
  }
}
